@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Nunito:ital,wght@0,400;0,700;1,400;1,800&family=Oswald:wght@500&display=swap');

// Import partials from `sass_dir` (defaults to `_sass`)
@import "normalize";
@import "base/variables";
@import "base/mixins";
@import "base/typography";
@import "base/layout";

.main-index {
  min-height: 100vh;
  
  color: #A3BFB7;
  background-color: #266573;
  background-image: linear-gradient(to bottom, #266573, #153840);
  
  .page-title {
    font-family: 'Calistoga', cursive;
    font-style: normal;
    font-weight: 400;
    @include font-rhythm($scale4);
    margin-top: lines(1);
    margin-bottom: lines(2);
    color: #40181F;
  }
  
  @include breakpoint(break-1) {
    .page-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
      max-width: unset;
      height: $grid-basis * 7;
      overflow: hidden;
    }
    
    .page-title {
      position: absolute;
      top: -50px;
      right: -30px;
      margin: 0;
      padding: 0;
      font-size: map-get($scale7, fontSize);
      line-height: 1;
    }
    
    .page-content {
      padding-top: $grid-basis * 7;
    }
  }
  
  a {
    color: #A3BFB7;
    @include link-decoration(#A3BFB7, #A3BFB7, $line-thickness)
  }
  
  dl {
    dt, dd {
      font-feature-settings: 'kern' 1, 'onum' 1, 'liga' 1;
      margin-left: 0;
    }
    
    dt {
      color: #40181F;
      font-weight: map-get($bodytype, bold);
    }
    
    dd + dt {
      padding-top: lines();
    }
  }
}
