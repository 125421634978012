// SCSS mixins
// ---------------------------------------

@mixin font-rhythm($size) {
  font-size: map-get($size, fontSize);
  line-height: map-get($size, autoLineHeight);
}

@mixin margins($size, $top, $bottom) {
  $line: map-get($size, line);
  margin-top: $top * $line;
  margin-bottom: $bottom * $line;
}

@function lines($multiplier: 1) {
  @return $grid-basis * $multiplier;
}

@mixin link-decoration($color, $hover-color, $thickness) {
  background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0) 50%,lighten($color,20%) 50%);
  background-position: 0 93%;
  background-repeat: repeat-x;
  background-size: 100% $thickness;
    
  &:hover, &:active, &:focus {
    background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0) 50%,lighten($hover-color,20%) 50%);
  }
}



// Number of breakpoints.
$breakpoints-limit: length($breakpoints);

// List of rootsizes, breakpoints, and max-widths.
$points: map-values($breakpoints);
$max-widths: map-values($maxwidths);


// Breakpoints. Either set one to one of the breakpoint variables, or use a custom value for minor breakpoints.
// Defaults to min-width, but both min->max and max-width are available too.
// Parts based on https://gist.github.com/timknight/03e6335b8816aa534cf7
@mixin breakpoint($break: 0, $max: 0) {
  // Type of break variable
  $value: type-of($break);

  // If it is a string (i.e. a breakpoint variable).
  @if $value == string {
    // If using 'break-1', 'break-2' etc output the correct breakpoints from map.
    @if map-has-key($breakpoints, $break) {
      @media screen and (min-width: map-get($breakpoints, $break) ) { @content; }
    } @else {
      @warn "#{$break} is not a set breakpoint variable";
    }

  // If it is a number, use this for the breakpoint.
  } @else if $value == number {
    // If using other numbers output value in ems either for min, min & max or max width breakpoints.
    $query: "all" !default;
    @if      $break != 0 and $max != 0 { $query: "(min-width: #{$break}) and (max-width: #{$max})"; }
    @else if $break != 0 and $max == 0 { $query: "(min-width: #{$break})"; }
    @else if $break == 0 and $max != 0 { $query: "(max-width: #{$max})"; }
    @media #{$query} { @content; }

  } @else {
    @warn "#{$break} is not valid to use as a breakpoint";
  }
}

// Max-widths for typeset containers, outputted in correct breakpoints.
@mixin maxwidth($breakpoint: 0) {
  // Type of chosen variables.
  $break-value: type-of($breakpoint);

  // If specifying a breakpoint to use (and breakpoint exists).
  @if $break-value == number and $breakpoint <= ($breakpoints-limit - 1) and $breakpoint >= 0 {

    max-width: nth($max-widths, ($breakpoint + 1));

  } @else if $breakpoint == all {
    max-width: nth($max-widths, 1);

    // Loop through breakpoints.
    @for $i from 2 through $breakpoints-limit {
      @media screen and (min-width: nth($points, $i) ) {
        max-width: nth($max-widths, $i);
      }
    }
  }
}

// Clearfix.
@mixin clearfix {
  &:before, &:after{
    display: table;
    content: "";
  }
  &:after{
    clear: both;
  }
}
