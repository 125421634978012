.wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-right: lines();
  padding-left: lines();
  
  @include maxwidth(all);
    
  @include breakpoint(break-1) {
    padding-left: 0;
    padding-right: 0;
  }
}
