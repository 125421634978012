// Typography
// ---------------------------------------

// Add typefaces here.
// Add weight and style details too.
// ! Set cap height to set to the baseline.
$bodytype: (
  font-family: 'Nunito, sans-serif',
  regular: 400,
  bold: 700,
  italic: italic
) !default;

$headingtype: (
  font-family: 'Oswald, sans-serif',
  regular: 500,
  bold: 500,
  cap-height: 0.7
) !default;

$titletype: (
  font-family: 'Nunito, sans-serif',
  regular: 400,
  bold: 800
) !default;

$monospacetype: (
  font-family: 'Menlo, monospace',
  regular: 400
) !default;



html, .root {
  font-size: map-get($root, fontSize);
  line-height: map-get($root, lineHeight);
}
body, .article {
  font-family: unquote(map-get($bodytype, font-family));
  font-style: normal;
  font-weight: map-get($bodytype, regular);
  @include font-rhythm($scale0);
}

h1, .h1 {
  @include font-rhythm($scale3);
  @include margins($scale3, 1, 2);
}
h2, .h2 {
  @include font-rhythm($scale2);
  @include margins($scale2, 1, 1);
}
h3, .h3 {
  @include font-rhythm($scale1);
  @include margins($scale2, 1, 0);
}
h4, .h4, h5, .h5 {
  @include font-rhythm($scale0);
  @include margins($scale0, 1, 0);
}

p, ul, ol, pre, table, blockquote {
  @include margins($scale0, 0, 1);
}
ul ul, ol ol, ul ol, ol ul {
  @include margins($scale0, 0, 0);
}

/* Let's make sure all's aligned */
hr, .hr {
  border: 1px solid;
  margin: -1px 0;
}
// a, b, i, strong, em, small, code {
//   line-height: 0;
// }
sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

// Links.
a {
  transition: color .1s, background-color .1s;
  text-decoration: none;

  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

strong { font-weight: bold; }
em { font-style: italic; }
