// SCSS variables
// ---------------------------------------

$grid-basis: 24px;
$line-thickness: 3px;


// ---------------------------------------

// Breakpoint sizes from px to ems. Add more values here to add more breakpoints.
// Change names if you prefer, it wont break the mixin as long as they are strings not just numbers.
$breakpoints: (
  break-0: 0px,     //   Mobile first
  break-1: 640px,   // ~ Small tablet up
) !default;

// Set the max-widths for containers (based on design).
// ! Make sure to have as many widths as breakpoints above.
$maxwidths: (
  width-0: 500px,
  width-1: 600px,
) !default;


// ---------------------------------------

$root: (
  fontSize: 18px,
  lineHeight: 24px
);
$scale-1: (
  fontSize: 0.7072em,
  line: 1.414em,
  autoLineCount: 1,
  autoLineHeight: 1.8853695324em
);
$scale0: (
  fontSize: 1em,
  line: 1.33333333em,
  autoLineCount: 1,
  autoLineHeight: 1.33333333em
);
$scale1: (
  fontSize: 1.38888889em,
  line: 0.96em,
  autoLineCount: 2,
  autoLineHeight: 1.92em
);
$scale2: (
  fontSize: 2em,
  line: 0.66666667em,
  autoLineCount: 2,
  autoLineHeight: 1.33333333em
);
$scale3: (
  fontSize: 2.83333333em,
  line: 0.47058824em,
  autoLineCount: 3,
  autoLineHeight: 1.41176471em
);
$scale4: (
  fontSize: 4em,
  line: 0.33333333em,
  autoLineCount: 3,
  autoLineHeight: 1em
);
$scale5: (
  fontSize: 5.66666667em,
  line: 0.23529412em,
  autoLineCount: 5,
  autoLineHeight: 1.17647059em
);
$scale6: (
  fontSize: 8em,
  line: 0.16666667em,
  autoLineCount: 6,
  autoLineHeight: 1em
);
$scale7: (
  fontSize: 11.27777778em,
  line: 0.1182266em,
  autoLineCount: 9,
  autoLineHeight: 1.06403941em
);
$scale8: (
  fontSize: 16em,
  line: 0.08333333em,
  autoLineCount: 12,
  autoLineHeight: 1em
);
$scale9: (
  fontSize: 22.61111111em,
  line: 0.05896806em,
  autoLineCount: 17,
  autoLineHeight: 1.002457em
);
$scale10: (
  fontSize: 31.94444444em,
  line: 0.04173913em,
  autoLineCount: 24,
  autoLineHeight: 1.00173913em
);
$scale11: (
  fontSize: 45.16666667em,
  line: 0.0295203em,
  autoLineCount: 34,
  autoLineHeight: 1.00369004em
);
$scale12: (
  fontSize: 63.88888889em,
  line: 0.02086957em,
  autoLineCount: 48,
  autoLineHeight: 1.00173913em
);